import { call, put, take, actionChannel, takeLatest, all } from 'redux-saga/effects';
import * as types from '../constants'
import { baseUrl } from '../../config'
import axios from 'axios';

function* fetchSetLevel(action) {
    try {
        let apiURL = baseUrl;
        const {comment, innerText, currentSelInfo} = action.data;

        for ( let i = 0; i < types.LEVELS.length; ++ i ) {
            if ( types.LEVELS[i] === comment ) {
                apiURL += innerText + '/'
                break
            } else {
                apiURL += currentSelInfo[types.LEVELS[i]] + '/'
            }
        }

        const response = yield axios.get(`${apiURL}`).then(response => response.data)
        console.log('server response>>', response)
        yield put({ type: types.SET_LEVEL_SUCCESS, data: {level: comment, response: response, innerText} })
    } catch (error) {
        yield put({ type: types.SET_LEVEL_FAILED, data: {level: action.data} })
    }
}

export function* setLevel() {
    const subChannel = yield actionChannel(types.SET_LEVEL);
    while (subChannel) {
        const action = yield take(subChannel);
        yield call(fetchSetLevel, action);
    }
}

export function* tempSaga() {
    yield all([
        takeLatest(types.SET_LEVEL, setLevel),
    ])
}