import React from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";
import "./ChangeuserName.css";
import { LoadingSpinner } from "../../common/LoadingSpinner";
import { changeAccountData } from "../../../services/account";

export class ChangePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      error: {
        newPassword: "",
        confirmPassword: "",
        form: ""
      },
      success: "",
      loading: false
    };
  }

  setNewPassword(value) {
    const { confirmPassword } = this.state;
    if (confirmPassword && confirmPassword !== value) {
      this.setState(state => ({
        ...state,
        newPassword: value,
        error: {
          ...state.error,
          newPassword: "New password and password confirmation must be same"
        }
      }));
    } else {
      this.setState(state => ({
        ...state,
        newPassword: value,
        error: {
          ...state.error,
          newPassword: ""
        }
      }));
    }
  }

  setConfirmPassword(value) {
    const { newPassword } = this.state;
    if (!newPassword) {
      this.setState(state => ({
        ...state,
        confirmPassword: value,
        error: {
          ...state.error,
          newPassword: "Password value cannot be empty"
        }
      }));
    } else if (newPassword !== value) {
      this.setState(state => ({
        ...state,
        confirmPassword: value,
        error: {
          ...state.error,
          newPassword: "",
          confirmPassword: "New password and password confirmation must be same"
        }
      }));
    } else {
      this.setState(state => ({
        ...state,
        confirmPassword: value,
        error: {
          ...state.error,
          newPassword: "",
          confirmPassword: ""
        }
      }));
    }
  }

  async changePassword() {
    this.setState({ loading: true });
    const data = { password: this.state.newPassword };
    const response = await changeAccountData(data);
    if (response.data) {
      this.setState(state => ({
        ...state,
        loading: false,
        newPassword: "",
        confirmPassword: "",
        success: "Password changed successfully"
      }));
    } else {
      this.setState(state => ({
        ...state,
        loading: false,
        error: {
          ...state.error,
          form: response.error
        }
      }));
    }
  }

  isDisabled() {
    const { newPassword, confirmPassword, error } = this.state;
    return (
      !newPassword ||
      !confirmPassword ||
      !!error.confirmPassword ||
      !!error.newPassword
    );
  }

  render() {
    const { error, success } = this.state;
    return (
      <>
        <LoadingSpinner loading={this.state.loading} />
        <Modal
          isOpen={this.props.isVisible}
          toggle={this.props.reverseIsVisible}
          centered={true}
          modalClassName="login-wrapper"
          className="login-modal"
        >
          <ModalHeader
            toggle={this.props.reverseIsVisible}
            cssModule={{ "modal-title": "w-100 text-left mb-0" }}
          >
            <div className="d-flex">
              <p style={{ textAlign: "left", marginLeft: 80, marginBottom: 0 }}>
                Change Password
              </p>
            </div>
          </ModalHeader>
          <ModalBody>
            <Form className="mt-3 change-userName-form">
              <FormGroup>
                <Label for="new-password">New password</Label>
                <Input
                  invalid={!!error.newPassword}
                  id="new-userName"
                  name="new-userName"
                  type="text"
                  value={this.state.newPassword}
                  onChange={e => this.setNewPassword(e.target.value)}
                />
                {error.newPassword && (
                  <FormFeedback>{error.newPassword}</FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="confirm-password">Confirm password</Label>
                <Input
                  invalid={!!error.confirmPassword}
                  id="confirm-userName"
                  name="confirm-userName"
                  type="text"
                  value={this.state.confirmPassword}
                  onChange={e => this.setConfirmPassword(e.target.value)}
                />
                {error.confirmPassword && (
                  <FormFeedback>{error.confirmPassword}</FormFeedback>
                )}
              </FormGroup>

              <Button
                color="primary"
                disabled={this.isDisabled()}
                onClick={() => this.changePassword()}
              >
                Change
              </Button>

              {error.form && (
                <div className="mt-2">
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {error.form}
                  </span>
                </div>
              )}
              {success && (
                <div className="mt-2">
                  <span style={{ fontSize: "14px", color: "green" }}>
                    {success}
                  </span>
                </div>
              )}
            </Form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
