import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import logo from "../../logoL.svg";
import "../../App.css";
import "./dynamicHeader.css";
import Login from "./login";
import SignupModal from "../SignupModal";
import HamburgerMenuButton from "./../hamburgerMenuButton";
import {
  /* getSessionuserName,*/ removeSession,
} from "./../../services/session";

const BE_URL = process.env.REACT_APP_BE_URL;

export default class DynamicHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {
        email: "",
        password: "",
        userName: "jkjjljjkljkjkljljkjiojjlk",
      },
      loggedInSupporter: false,
      activeTab: "3",
      orgs: [
        { title: "U.S.A. Voting", site: `${BE_URL}` },
        { title: "ISPPW", site: "https://www.google.com" },
      ],
      dropdownOpen: false,
      showSignupModal: false,
      showLoginModal: false,
      contentBody: 0,
    };

    this.sessionUpdated = this.sessionUpdated.bind(this);
  }

  signupButtonStyles = () => {
    return {
      cursor: "pointer",
      outline: "none",
      textAlign: "center",
      border: "none",
      color: "rgb(66, 66, 66)",
      fontFamily: [
        "GillSans",
        "Gill Sans MT",
        "Gill Sans",
        "Trebuchet MS",
        "sans-serif",
      ],
      textDecoration: "underline",
      backgroundColor: "#fffff4",
      marginTop: "3",
      paddingTop: "3",
      boxSizing: "content-box",
      outerWidth: "100%",
      whiteSpace: "nowrap",
    };
  };

  toggleModalVisibility() {
    this.setState((prevState) => ({
      ...prevState,
      showSignupModal: !prevState.showSignupModal,
    }));
  }

  toggleLoginModalVisibility() {
    this.setState((prevState) => ({
      ...prevState,
      showLoginModal: !prevState.showLoginModal,
    }));
  }

  logout() {
    removeSession();
    setTimeout(() => this.props.sessionUpdated(), 0);
  }

  sessionUpdated() {
    this.setState({ showLoginModal: false });
    setTimeout(() => this.props.sessionUpdated(), 0);
  }

  render() {
    if (!this.props.session) return this.renderWithoutSession();
    else return this.renderWithSession();
  }

  renderWithoutSession() {
    return (
      <Row
        className="dynamic-header"
        style={{
          minHeight: "48px",
          color: "rgb(66, 66, 66)",
          marginTop: 10,
          textAlign: "center",
          paddingRight: 20,
        }}
      >
        <Col xs="2" sm="3" md="4" style={{ textAlign: "center" }}>
          <a
            style={this.signupButtonStyles()}
            href="https://swarmage.org"
            target="_blank"
            rel="noopener noreferrer"
            data-toggle="tooltip"
            title="Coming soon: Create your own movement's Swarm Age page for free!"
          >
            <img
              src={logo}
              height="60px"
              style={{ marginTop: "-10px" }}
              alt="logo"
            />
          </a>
        </Col>
        <Col
          xs="8"
          sm="6"
          md="4"
          style={{ textAlign: "center", backgroundColor: "transparent" }}
        >
          <div>
            <button
              style={{ ...this.signupButtonStyles() }}
              data-toggle="tooltip"
              title="Click here to sign up to support this movement.  There is no cost."
              onClick={(e) => this.toggleModalVisibility()}
            >
              <strong style={{ backgroundColor: "transparent" }}>
                become a supporter
              </strong>
            </button>
            <br />
            <i>use Chrome or Safari</i>
            <SignupModal
              isOpen={this.state.showSignupModal}
              toggleVisibility={(e) => this.toggleModalVisibility()}
            />
          </div>
        </Col>
        <Col
          xs="2"
          sm="3"
          md="4"
          style={{
            border: "none",
            textAlign: "center",
          }}
        >
          <div>
            <button
              style={this.signupButtonStyles()}
              data-toggle="tooltip"
              title="Click to login to your account"
              onClick={() => this.toggleLoginModalVisibility()}
            >
              log in
            </button>
            <Login
              isOpen={this.state.showLoginModal}
              toggleLoginVisibility={(e) => this.toggleLoginModalVisibility()}
              sessionUpdated={this.sessionUpdated}
            />
          </div>
        </Col>
      </Row>
    );
  }

  renderWithSession() {
    return (
      <Row
        className="dynamic-header"
        style={{ minHeight: "48px", color: "rgb(66, 66, 66)" }}
      >
        <Col
          xs="6"
          sm="6"
          md="4"
          style={{
            paddingLeft: 6,
            paddingRight: 40,
            marginLeft: "auto",
            marginright: "auto",
            marginTop: "9px",
            textAlign: "left",
          }}
        >
          <div postion="absolute" style={{ textAlign: "center" }}>
            <span
              style={{
                color: "red",
              }}
            >
              {this.props.session.userName}
            </span>
          </div>
        </Col>
        <Col
          xs="2"
          sm="3"
          md="4"
          style={{
            paddingLeft: 1,
            paddingRight: 1,
            marginTop: 6,
            marginLeft: "auto",
            marginright: "auto",
            textAlign: "center",
          }}
        >
          <div className="d-flex justify-content-center">
            <HamburgerMenuButton
              color="#424242"
              open={this.props.hamburgerMenuOpen}
              onClick={() => this.props.toggleHamburgerMenu()}
            />
          </div>
        </Col>
        <Col
          xs="3"
          sm="3"
          md="4"
          style={{
            border: "none",
            marginTop: 6,
            paddingTop: 2,
            paddingLeft: 4,
            marginright: 4,
            paddingRight: 0,
            marginLeft: "auto",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <button
              style={this.signupButtonStyles()}
              onClick={() => this.logout()}
            >
              log_out
            </button>
          </div>
        </Col>
      </Row>
    );
  }
}
