import React from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { getSession, setSession } from "../../../services/session";
import { changeAccountData } from "../../../services/account";
import { LoadingSpinner } from "../../common/LoadingSpinner";
import "./ChangeNotifications.css";

export class ChangeuserNotificationsPage extends React.Component {
  constructor(props) {
    super(props);
    const { coreSupporter } = this.props.session;
    this.state = {
      coreSupporter: coreSupporter,
      error: {
        coreSupporter: coreSupporter,
        form: "",
      },
      success: "",
      loading: false,
    };
  }

  //   setuserNotifications(value) {
  //     const { coreSupporter } = this.props.session;
  //     if (value === coreSupporter) {
  //       this.setState((state) => ({
  //         ...state,
  //         coreSupporter: value,
  //         // error: {
  //         //   ...state.error,
  //         //   userName: "New userName can't be same as old one",
  //         // },
  //       }));
  //     } else
  //       this.setState((state) => ({
  //         ...state,
  //         coreSupporter: value,
  //         // error: {
  //         //   ...state.error,
  //         //   userName: "",
  //         // },
  //       }));
  //   }

  setCoreSupporter(value) {
    let a = this.state.coreSupporter === true ? false : true;
    console.log(a);
    console.log(this.state.coreSupporter);
    this.setState((state) => ({
      ...state,
      coreSupporter: a,
    }));
    console.log(a);
    console.log(this.state.coreSupporter);
  }

  async changeuserNotifications() {
    console.log(this.state.coreSupporter);
    // Reset error state
    this.setState((state) => ({
      ...state,
      error: { ...state.error, form: "" },
      success: "",
      loading: true,
    }));

    // Perform API call
    const data = { coreSupporter: this.state.coreSupporter };
    const response = await changeAccountData(data);
    if (response.data) {
      console.log("reponse.data is good:  ", data);
      const session = { ...getSession(), coreSupporter: data.coreSupporter };
      setSession("session", session);
      this.setState({
        success: "Notification status changed successfully",
        // coreSupporter: "",
        loading: false,
      });
      this.props.sessionUpdated();
    } else {
      this.setState((state) => ({
        ...state,
        error: {
          ...state.error,
          form: response.error,
        },
        loading: false,
      }));
    }
  }

  render() {
    const { error, coreSupporter, success } = this.state;
    return (
      <>
        <LoadingSpinner loading={this.state.loading} />
        <Modal
          isOpen={this.props.isVisible}
          toggle={this.props.reverseIsVisible}
          centered={true}
          modalClassName="login-wrapper"
          className="login-modal"
        >
          <ModalHeader
            toggle={this.props.reverseIsVisible}
            cssModule={{ "modal-title": "w-100 text-left mb-0" }}
          >
            <div className="d-flex">
              <p style={{ textAlign: "left", marginLeft: 80, marginBottom: 0 }}>
                Change notification setting
              </p>
            </div>
          </ModalHeader>
          <ModalBody>
            <Form>
              {/* <Form className="mt-3 change-userName-form"> */}
              <FormGroup>
                <div
                  id="holder"
                  onChange={(e) => this.setCoreSupporter(e.target.value)}
                >
                  <Input
                    id="coreSupporter"
                    className="regular-checkbox big-checkbox"
                    //
                    // invalid={!!error.coreSupporter}
                    type="checkbox"
                    name="coreSupporter"
                    checked={coreSupporter}
                  />

                  <Label
                    style={{ transform: "transform(0px,50px)" }}
                    for="coreSupporter"
                  ></Label>
                  <div class="coreSupporterComment">
                    {" "}
                    Check this box to not miss important updates and surveys for
                    draft legistlation
                  </div>
                </div>
              </FormGroup>
              {/* </FormGroup><FormGroup>
                <Label for="current-userName">Current user name</Label>
                <Input
                  id="current-userName"
                  name="current-userName"
                  type="text"
                  disabled={true}
                  value={this.props.session.userName}
                />
              </FormGroup>

              <FormGroup>
                <Label for="new-userName">New user name</Label>
                <Input
                  invalid={!!error.userName}
                  name="new-userName"
                  id="new-userName"
                  type="text"
                  value={this.state.userName}
                  onChange={(e) => this.setuserNotifications(e.target.value)}
                />
                {error.userName && (
                  <FormFeedback>{error.userName}</FormFeedback>
                )}
              </FormGroup> */}

              <Button
                // disabled={!!this.state.error.coreSupporter}
                color="primary"
                onClick={() => this.changeuserNotifications()}
              >
                Change
              </Button>

              {error.form && (
                <div className="mt-2">
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {error.form}
                  </span>
                </div>
              )}
              {success && (
                <div className="mt-2">
                  <span style={{ fontSize: "14px", color: "green" }}>
                    {success}
                  </span>
                </div>
              )}
            </Form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
