import React, { Component } from "react";
import * as colorConst from './constant'
import'./ImageButton.css'


class ImageButton extends Component {

    onClick() {
        const { type, selected, setMapView, percentViewMode, setPercentView } = this.props
        if ( type === 0 || type === 1 )
            setMapView(!selected)
        else
            setPercentView(!percentViewMode)
    }

    renderLaughImage() {
        const { selected } = this.props
        const fillColor = selected ? colorConst.BUTTON_ON_FILL : colorConst.BUTTON_OFF_FILL
        const lineColor = selected ? colorConst.BUTTON_ON_LINES : colorConst.BUTTON_OFF_LINES

        return (
            <svg viewBox = "0 0 100 100" width = '100%' height = '100%' fill= '#FFFFCD'>
                <rect width = '100%' height = '100%' fill = { fillColor } />
                <path d="M16 32 Q24 24 32 32 Q24 20 16 32" stroke={lineColor} fill={lineColor}/>
                <path d="M48 32 Q56 24 64 32 Q56 20 48 32" stroke={lineColor} fill={lineColor}/>
                <path d="M24 40 Q40 56 56 40 Q40 72 24 40" stroke={lineColor} fill={lineColor}/>
                <line x1="0" y1="80" x2="100" y2="80" style={{ stroke: lineColor, strokeWidth: 2}} />
                <line x1="80" y1="0" x2="80" y2="100" style={{ stroke: lineColor, strokeWidth: 2}} />
                <circle cx="86" cy="84" r="2" fill={lineColor} />
                <circle cx="94" cy="84" r="2" fill={lineColor} />
                <line x1="84" y1="96" x2="96" y2="96" style={{ stroke: lineColor, strokeWidth: 2}} />
            </svg>
        )
    }

    renderSilenceImage() {
        const { selected } = this.props
        const fillColor = !selected ? colorConst.BUTTON_ON_FILL : colorConst.BUTTON_OFF_FILL
        const lineColor = !selected ? colorConst.BUTTON_ON_LINES : colorConst.BUTTON_OFF_LINES
        return (
            <svg viewBox = "0 0 100 100" width = '100%' height = '100%'>
                <rect width = '100%' height = '100%' fill = { fillColor } />
                <path d="M84 88 Q86 86 88 88 Q86 85 84 88" stroke={lineColor} fill={lineColor}/>
                <path d="M92 88 Q96 86 96 88 Q96 85 92 88" stroke={lineColor} fill={lineColor}/>
                <path d="M86 90 Q90 94 94 90 Q90 98 86 90" stroke={lineColor} fill={lineColor}/>
                <line x1="0" y1="80" x2="100" y2="80" style={{ stroke: lineColor, strokeWidth: 2}} />
                <line x1="80" y1="0" x2="80" y2="100" style={{ stroke: lineColor, strokeWidth: 2}} />
                <circle cx="24" cy="20" r="8" fill={lineColor} />
                <circle cx="56" cy="20" r="8" fill={lineColor} />
                <line x1="16" y1="64" x2="64" y2="64" style={{ stroke: lineColor, strokeWidth: 2}} />
            </svg>
        )
    }

    renderTotalPercentButton() {
        const { percentViewMode, type } = this.props
        const fillColor = (type === 2 && !percentViewMode) ? colorConst.BUTTON_ON_FILL : colorConst.BUTTON_OFF_FILL

        return (
            <div className = 'percent-view' style = {{backgroundColor: fillColor}}>
                { type === 2 && <div>total<br/>%</div> }
                { type === 3 && <div>% last<br/>10 days</div> }
            </div>
        )
    }

    render() {
        const { selected, type, percentViewMode } = this.props
        const borderStyle = ((type === 1 && !selected) || (type === 0 && selected) || (type === 2 && !percentViewMode) || (type === 3 && percentViewMode)) ? `5px solid ${colorConst.BUTTON_ON_BORDER}` : `2px solid ${colorConst.BUTTON_OFF_BORDER}`

        return (
            <div className = 'image-button'
                style = {{ border : borderStyle}}
                onClick = {() => this.onClick()}
            >
                { type === 1 && this.renderSilenceImage()}
                { type === 0 && this.renderLaughImage() }
                { (type === 2 || type === 3) &&this.renderTotalPercentButton() }
            </div>
        )
    }
}

export default ImageButton