import React, { Component } from "react";
import { Col } from "reactstrap";
import './ParentBlock.css'

const pluralComment = {
    nation: 'states',
    state: 'counties',
    county: 'precints',
}

class ParentBlock extends Component {
    onClick() {
        const { isOpened } = this.props
        if ( isOpened ) {
            this.props.handleClick()
        } else {
            this.props.arrowClick()
        }
    }

    render() {
        const { comment, innerText, isOpened, noComment} = this.props;

        return (
            <Col
                xs="4"
                sm="4"
                md="4"
                className = 'parent-block'
                style = {{backgroundColor: this.props.blockColor, color: this.props.textColor}}
                onClick = {() => this.onClick()}
            >
                {(comment !== 'precincts' && !noComment) && <span className = "tooltiptext">select to see dropdown list of all {pluralComment[comment]}</span>}
                <div className = 'text-block'>
                    <div className = 'comment-block'>
                        {!noComment ? comment + ':' : ''}
                    </div>
                    <div className = 'innerText-block'>
                        {innerText}
                    </div>
                </div>

                {
                    comment !== 'precinct' ? <div className = 'arrow-block'>
                        <span className = {`arrow${isOpened === 1 ? ' arrow-opened': ''}`}> ❯ </span>
                    </div> : <></>
                }
            </Col>
        )
    }
}

export default ParentBlock;