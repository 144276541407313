import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import "./login.css";
import "../../App.css";
// import { validateEmail, validatePassword } from "./../util";
import * as authServices from "../../services/authServices";
import { LoadingSpinner } from "../common/LoadingSpinner";
import * as supporter from "./../../services/supporter";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: "",
      form: {
        email: "",
        password: ""
      },
      yourPassword: ""
    };
    this.loginSupporter = this.loginSupporter.bind(this);
  }

  async loginSupporter(e) {
    this.setState({ loading: true, error: "" });
    try {
      e.preventDefault();
      const reqBody = {
        email: this.state.form.email,
        password: this.state.form.password
      };
      let response = await authServices.loginSupporter(reqBody);
      if (response.data) {
        this.setState({ loading: false });
        setTimeout(() => this.props.sessionUpdated(), 0);
      } else {
        this.setState({ error: response.error, loading: false });
      }
    } catch (e) {
      console.warn("While performing login", e);
      this.setState({ error: e.message || e, loading: false });
    }
  }

  async forgotPassword(e) {
    this.setState({ loading: true, error: "" });
    console.log("in forgot password");
    try {
      e.preventDefault();
      const reqBody = {
        email: this.state.form.email
      };
      let response = await authServices.forgotPassword(reqBody);
      if (response.data) {
        const password = response.data;
        const { email } = reqBody;
        console.log("in forgot password", password);
        let secondResponse = await supporter.forgottenPassword({
          email,
          password
        });
        if (secondResponse.data) {
          console.log(secondResponse);
          this.setState({ loading: false, yourPassword: secondResponse.data });
        } else {
          console.log("fail one");
          this.setState({ error: secondResponse.error, loading: false });
        }
      } else {
        console.log("fail two");
        this.setState({ error: response.error, loading: false });
      }
    } catch (e) {
      console.warn("While performing login", e);
      this.setState({ error: e.message || e, loading: false });
    }
  }

  setEmail(value) {
    // const err = validateEmail(value);
    this.setState(state => ({
      form: {
        ...state.form,
        email: value
      }
    }));
  }

  setPassword(value) {
    // const err = validatePassword(value);
    this.setState(state => ({
      form: {
        ...state.form,
        password: value
      }
    }));
  }

  render() {
    const { yourPassword } = this.state;
    const { email, password } = this.state.form;
    const { isOpen, toggleLoginVisibility } = this.props;

    return (
      <>
        <LoadingSpinner loading={this.state.loading} />
        <Modal
          backdrop={"static"}
          isOpen={isOpen}
          toggle={toggleLoginVisibility}
          centered={true}
          modalClassName="login-wrapper"
          className="login-modal"
        >
          <ModalHeader
            toggle={toggleLoginVisibility}
            cssModule={{ "modal-title": "w-100 text-center mb-0" }}
          >
            <div className="d-flex justify-content-center">
              <p style={{ textAlign: "center", marginBottom: 0 }}>LOGIN</p>
            </div>
          </ModalHeader>

          <ModalBody>
            <Form>
              <FormGroup className="mb-0">
                <div className="">
                  <Label for="email">Email</Label>
                  <Input
                    type="text"
                    name="email"
                    id="email"
                    value={email}
                    onChange={e => this.setEmail(e.target.value)}
                  />
                </div>

                <div className="mt-3">
                  <Label for="password">Password</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={e => this.setPassword(e.target.value)}
                  />
                </div>

                <div className="mt-3">
                  <Button
                    color="default"
                    size="sm"
                    onClick={e => this.forgotPassword(e)}
                  >
                    Forgot Password?
                  </Button>
                </div>

                {yourPassword && (
                  <div className="mt-3">
                    Your password is <strong>{yourPassword}</strong>
                  </div>
                )}

                <div className="mt-3">
                  <Button
                    type="submit"
                    color="primary"
                    size="sm"
                    onClick={e => this.loginSupporter(e)}
                  >
                    Login
                  </Button>
                </div>

                <div className="error mt-2">
                  <span style={{ fontSize: "14px", color: "red" }}>
                    {this.state.error}
                  </span>
                </div>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default LoginForm;
