export const validateEmail = (value) => {
  if (!value) return "";
  const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_REGEX.test(value) ? "" : "Email is not valid";
};

export const validateuserName = (value) => {
  if (!value) return "";
  if (value.length >= 4) return "";
  return "userName should atleast be 4 characters long";
};

export const validatePassword = (value) => {
  if (!value) return "";
  if (value.length >= 8) return "";
  return "Password should atleast be 8 characters long";
};
export const validateCoreSupporter = (value) => {
  return;
};
