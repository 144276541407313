import React, { Component } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import axios from "axios";
import flodingMap from "../../folding-map.svg";
import "font-awesome/css/font-awesome.min.css";

import "./SignupFlow2.css";

const BE_URL = process.env.REACT_APP_BE_URL;
export default class SignupFlow3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      precinctList: []
    };
    this.source = null;
  }

  async componentWillMount() {
    if (this.source) {
      this.source.cancel("ERR_CANCELLED");
    }

    const CancelToken = axios.CancelToken;
    this.source = CancelToken.source();

    const { state, county } = this.props;
    if (!state || !county) return;

    this.props.setLoading(true);

    try {
      const response = await axios.get(
        `${BE_URL}/api/total/supporters/USA/${state}/${county}`,
        {
          cancelToken: this.source.token
        }
      );

      const data = response.data;
      if (data && typeof data.subordinates === "object") {
        const precinctList = Object.keys(data.subordinates)
          .map(supporter => supporter)
          .sort();
        this.setState({ precinctList, dropdownOpen: true });
      }
      this.source = null;
    } catch (e) {
      if (e.message === "ERR_CANCELLED") return;
      this.setState({ precinctList: [] });
    } finally {
      this.props.setLoading(false);
    }
  }

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel("ERR_CANCELLED");
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    const { county, precinct } = this.props;
    const precincts = this.state.precinctList.map((item, index) => {
      return (
        <DropdownItem
          key={index}
          onClick={e => {
            console.log(item);
            this.props.setSelectedPrecinct(item);
          }}
        >
          {item}
        </DropdownItem>
      );
    });

    return (
      <div className="sf-2">
        <p>
          In which precinct in <b>{county || "selected county"}</b> are you
          registered to vote?&nbsp;&nbsp;&nbsp;
          <a
            href="https://www.vote.org/polling-place-locator/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={flodingMap}
              width="46px"
              height="46px"
              alt="foldingMap"
              title="want help finding your precinct? (opens new window)"
            />
          </a>{" "}
          (find my precinct)
        </p>
        <div className="dd-row">
          <Dropdown
            className="dd"
            isOpen={this.state.dropdownOpen}
            toggle={() => this.toggleDropdown()}
          >
            <DropdownToggle caret className="dd-toggle">
              {precinct || "Select a precinct"}
            </DropdownToggle>
            <DropdownMenu
              className="dd-menu"
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: data => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: 100
                      }
                    };
                  }
                }
              }}
            >
              {precincts}
            </DropdownMenu>
          </Dropdown>
          <Button
            size="sm"
            className="dd-back-btn"
            outline
            color="secondary"
            onClick={e => this.props.previousSlide()}
          >
            Reselect County
          </Button>
        </div>
      </div>
    );
  }
}
