// program works at this point
import React from "react";
import {
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { BrowserView, MobileView } from "react-device-detect";
import { validateEmail, validateuserName, validatePassword } from "./../util";

import * as supporter from "./../../services/supporter";
import "./SignupFlow4.css";
export default class SignupFlow4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: null,
      form: {
        email: "",
        userName: "",
        password: "",
        coreSupporter: true,
        code: "",
        error: {
          email: "",
          userName: "",
          password: "",
          coreSupporter: true,
          code: "",
        },
      },
      sendVerification: {
        error: "",
        success: "",
      },
      verifyCode: {
        error: "",
        success: "",
      },
    };
    this.recaptchaRef = React.createRef();
    this.captchaTimer = null;
  }

  setuserName(value) {
    const err = validateuserName(value);
    this.setState((state) => ({
      form: {
        ...state.form,
        userName: value,
        error: {
          ...state.form.error,
          userName: err,
        },
      },
    }));
  }

  setPassword(value) {
    const err = validatePassword(value);
    this.setState((state) => ({
      form: {
        ...state.form,
        password: value,
        error: {
          ...state.form.error,
          password: err,
        },
      },
    }));
  }

  setEmail(value) {
    const err = validateEmail(value);
    this.setState((state) => ({
      form: {
        ...state.form,
        email: value,
        code: "",
        error: {
          ...state.form.error,
          email: err,
        },
      },
      sendVerification: { error: "", success: "" },
      verifyCode: { error: "", success: "" },
    }));
  }

  // reverses what coreSupporter is, starts as true
  setCoreSupporter(value) {
    let a = this.state.form.coreSupporter === true ? false : true;
    this.setState((state) => ({
      form: {
        ...state.form,
        coreSupporter: a,
      },
    }));
  }
  setCode(value) {
    this.setState((state) => ({
      form: {
        ...state.form,
        code: value,
      },
    }));
  }

  handleCaptchaChange(value) {
    this.setState({ captcha: value });
  }

  async sendVerificationEmail() {
    //call from button to send email
    this.props.setLoading(true);
    const response = await supporter.sendVerificationEmail(
      this.state.form.email
    );
    this.props.setLoading(false);
    if (response.error) {
      this.setState((state) => ({
        ...state,
        form: {
          ...state.form,
          code: "",
        },
        sendVerification: { error: response.error, success: "" },
      }));
    } else {
      this.setState((state) => ({
        ...state,
        form: {
          ...state.form,
          code: "",
        },
        sendVerification: { error: "", success: response.data },
      }));
    }
    if (!this.state.captcha) {
      this.executeRecaptcha();
    }
  }

  executeRecaptcha() {
    if (this.recaptchaRef && this.recaptchaRef.current) {
      this.recaptchaRef.current.execute();
    }
  }

  async verifyCode() {
    this.props.setLoading(true);
    const response = await supporter.verifyCode(
      this.state.form.email,
      this.state.form.code
    );
    this.props.setLoading(false);
    if (response.error) {
      this.setState({ verifyCode: { error: response.error } });
    } else {
      this.props.register(
        this.state.form.email,
        this.state.form.userName,
        this.state.form.password,
        this.state.form.coreSupporter
      );
    }
  }
  toggleCheck() {
    this.setState({ isChecked: !this.state.isChecked });
  }
  isBtnDisabled() {
    return !this.state.form.code || !this.state.captcha;
  }

  render() {
    const { sendVerification, form, verifyCode } = this.state;
    const { code, email, userName, password, error, coreSupporter } = form;
    const { registerResponse } = this.props;
    return (
      <div className="sf-4">
        <Form>
          <FormGroup>
            <Label for="userName">userName</Label>
            <Input
              invalid={!!error.userName}
              type="text"
              name="userName"
              id="userName"
              value={userName}
              onChange={(e) => this.setuserName(e.target.value)}
            />
            {error.userName && <FormFeedback>{error.userName}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <div
              id="holder"
              onChange={(e) => this.setCoreSupporter(e.target.value)}
            >
              <Input
                id="coreSupporter"
                className="regular-checkbox big-checkbox"
                //
                // invalid={!!error.coreSupporter}
                type="checkbox"
                name="coreSupporter"
                checked={this.state.form.coreSupporter}
              />

              <Label
                style={{ transform: "transform(0px,50px)" }}
                for="coreSupporter"
              ></Label>
              <div class="coreSupporterComment">
                {" "}
                Check this box to not miss important updates and surveys for
                draft legistlation!
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              invalid={!!error.password}
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={(e) => this.setPassword(e.target.value)}
            />
            {error.password && <FormFeedback>{error.password}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <InputGroup>
              <Input
                invalid={!!error.email}
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={(e) => this.setEmail(e.target.value)}
              />
              <InputGroupAddon addonType="append">
                <Button
                  onClick={() => this.sendVerificationEmail()} //sendGrid call
                  disabled={!!error.email || !email}
                  color="primary"
                  size="sm"
                >
                  Verify
                </Button>
              </InputGroupAddon>
            </InputGroup>
            {error.email && (
              <span
                style={{
                  width: "100%",
                  marginTop: "0.25rem",
                  fontSize: "80%",
                  color: "#dc3545",
                }}
              >
                {error.email}
              </span>
            )}
            {sendVerification.error && (
              <span
                style={{
                  width: "100%",
                  marginTop: "0.25rem",
                  fontSize: "80%",
                  color: "#dc3545",
                }}
              >
                {sendVerification.error}
              </span>
            )}
          </FormGroup>

          <MobileView>
            <ReCAPTCHA
              ref={this.recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_CAPTCHA_MOBILE}
              onChange={(value) => this.handleCaptchaChange(value)}
            />
          </MobileView>

          {sendVerification.success && (
            <>
              <hr className="hr" />
              <span style={{ color: "green", fontSize: "14px" }}>
                A verification email containing verification code has been sent
                to the provided email. <br />
                <strong>It may come to the junk folder!</strong>
                <br /> Please paste the verification code here.
              </span>
              <FormGroup className="mt-2 mb-0">
                <Label for="code">Verification code</Label>
                <Input
                  type="text"
                  name="code"
                  id="code"
                  value={code}
                  onChange={(e) => this.setCode(e.target.value)}
                />
              </FormGroup>

              <BrowserView>
                <ReCAPTCHA
                  className="mt-3"
                  size="normal"
                  sitekey={process.env.REACT_APP_CAPTCHA_BROWSER}
                  onChange={(value) => this.handleCaptchaChange(value)}
                />
              </BrowserView>

              <Row className="mx-0 mt-3 justify-content-between">
                <Button
                  disabled={this.isBtnDisabled()}
                  size="sm"
                  color="primary"
                  onClick={() => this.verifyCode()}
                >
                  Complete Registration
                </Button>
                <Button
                  className="mt-3"
                  size="sm"
                  outline
                  color="secondary"
                  onClick={() => this.props.previousSlide()}
                >
                  Reselect Precinct
                </Button>
              </Row>
              {(verifyCode.error ||
                (registerResponse && registerResponse.error)) && (
                <span
                  style={{
                    width: "100%",
                    marginTop: "0.25rem",
                    fontSize: "80%",
                    color: "#dc3545",
                  }}
                >
                  {verifyCode.error || registerResponse.error}
                </span>
              )}
            </>
          )}
        </Form>
      </div>
    );
  }
}
