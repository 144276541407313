import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const DropDown = props => {
  const { show, toggle } = props;

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} className="dropdown-content">
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <a
            href="https://falkvinge.net/files/2013/04/Swarmwise-2013-by-Rick-Falkvinge-v1.1-2013Sep01.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about Swarms from Falkvinge's free book.
          </a>
          <a
            href="https://ScoreVoting.SwarmAge.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            Score Voting Movement
          </a>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DropDown;
