import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import TOU from "./containers/TOU";
import PP from "./containers/PP";
import Central from "./central";

class App extends Component {
  render() {
    return (
      <div classname="navi">
        <Switch>
          <Route path="/TermsOfUsage" component={TOU} />
          <Route path="/PrivacyPolicy" component={PP} />
          <Route path="/" component={Central} />
        </Switch>
      </div>
    );
  }
}

export default App;
