import React, { Component } from "react";

class TOU extends Component {
  render() {
    return (
      <div
        align="full"
        style={{
          width: "90%",
          maxWidth: "1000px",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <br />
        <br />
        <p align="center">
          <strong>
            <u>SWARM AGE LLC</u>
          </strong>
        </p>
        <div align="center">
          <hr size="0" width="100%" align="center" />
        </div>
        <p align="center">
          <strong>
            <u></u>
          </strong>
        </p>
        <p align="center">
          <strong>TERMS OF SERVICE</strong>
        </p>
        <p>
          These Terms of Service ("<strong>Terms</strong>") constitute a legally
          binding agreement ("<strong>Agreement</strong>") between you and SWARM
          AGE LLC ("COMPANY") ) governing your access to and use of the COMPANY
          website entitled [www.SwarmAge.net] including any subdomains
          thereof("Site") , and any other websites through which COMPANY makes
          the Services available (collectively, the "<strong>Sites</strong>"),
          our mobile, tablet and other smart device applications, and
          application program interfaces (collectively, "
          <strong>Application</strong>") and all associated services
          (collectively, "<strong>Services</strong>"). The Site, Application and
          Services together are hereinafter collectively referred to as the "{" "}
          <strong>Site</strong>."
        </p>
        <p>
          Please read these Terms of Service carefully as they contain important
          information about your legal rights, remedies and obligations.
          <strong>
            By accessing or using the Site, you agree to comply with and be
            bound by these Terms.
          </strong>
        </p>
        <p>
          PLEASE NOTE: THESE TERMS OF SERVICE CONTAINS AN ARBITRATION CLAUSE AND
          CLASS ACTION WAIVER THAT APPLIES TO ALL USERS. If you reside in the
          United States, this provision applies to all disputes with COMPANY. If
          you reside outside of the United States, this provision applies to any
          action you bring against COMPANY in the United States. It affects how
          disputes with COMPANY are resolved. By accepting these Terms of
          Service, you agree to be bound by this arbitration clause and class
          action waiver. Please read it carefully.
        </p>
        <p>
          When these Terms mention "<strong>COMPANY</strong>," "
          <strong>we</strong>," "<strong>us</strong>," or "<strong>our</strong>
          ," it refers to the COMPANY, Inc., a Nevada limited liability company
          and the company you are contracting with in this Agreement.
        </p>
        <p>
          <a name="sec1"></a>
          <strong>1. </strong>
          <strong>
            <u>Scope of Services</u>
          </strong>
        </p>
        <p>The Site is an online platform that enables:</p>
        <p>
          (i) users, web-based tool that empowers movement leaders to take
          advantage of proven "swarm" practices by affording all of their
          supporters a clear idea of where the movement is strong, where it is
          weak, and how close it is to achieving its goal.
        </p>
        <p>
          (ii) advertisers the ability to target potential buyers based on the
          viewers location and concepts they are discussing.
        </p>
        <p>
          (iii) allows advertisers to set cost limits and anticipate costs based
          on historical models of how many views and clicks their ad may
          receive.
        </p>
        <p>
          (iv) advocates to create and manage swarms of supporters around
          initiatives, from local to national, that affect laws and elect
          leaders. Following the principles of swarm-activism, the Site allows
          all members of a grass-roots movement to understand their next
          objective and their progress toward it
        </p>
        <p>
          (i)-(iv) (the <strong>"Purpose</strong>").
        </p>
        <p>
          If you choose to use the Site as a User or Advertiser, your
          relationship with COMPANY is limited to being an independent user, and
          not an employee, agent, joint venturer or partner of COMPANY for any
          reason, and you act exclusively on your own behalf and for your own
          benefit, and not on behalf, or for the benefit, of COMPANY.
        </p>
        <p>
          1.1 The Site may contain links to third-party websites or resources ("{" "}
          <strong>Third-Party Services</strong>"). Such Third-Party Services may
          be subject to different terms and conditions and privacy practices.
          COMPANY is not responsible or liable for the availability or accuracy
          of such Third-Party Services, or the content, products, or services
          available from such Third-Party Services. Links to such Third-Party
          Services are not an endorsement by COMPANY of such Third-Party
          Services.
        </p>
        <p>
          1.2 Due to the nature of the Internet, COMPANY cannot guarantee the
          continuous and uninterrupted availability and accessibility of the
          Site. COMPANY may restrict the availability of the Site or certain
          areas or features thereof, if this is necessary in view of capacity
          limits, the security or integrity of our servers, or to carry out
          maintenance measures that ensure the proper or improved functioning of
          the Site. COMPANY may improve, enhance and modify the Site and
          introduce new Services from time to time.
        </p>
        <p>
          <a name="sec2"></a>
          <strong>2. </strong>
          <strong>
            <u>Eligibility, Using the Site, User Verification</u>
          </strong>
        </p>
        <p>
          2.1 You must be at least 18 years old and able to enter into legally
          binding contracts to access and use the Site or register a COMPANY
          account. By accessing or using the Site you represent and warrant that
          you are 18 or older and have the legal capacity and authority to enter
          into a contract.
        </p>
        <p>
          2.2 COMPANY may make the access to and use of the Site, or certain
          areas or features of the Site and/or COMPANY's products and Services,
          subject to certain conditions or requirements, such as completing a
          verification process or meeting specific quality or eligibility
          criteria.
        </p>
        <p>
          <a name="sec3"></a>
          <strong>3. </strong>
          <strong>
            <u>Modification of These Terms</u>
          </strong>
        </p>
        <p>
          COMPANY reserves the right to modify these Terms at any time in
          accordance with this provision. If we make changes to these Terms, we
          will post the revised Terms on the Site. If you disagree with the
          revised Terms, you may terminate this Agreement with immediate effect
          by following the procedure described in the "Term and Termination"
          section below. If you do not terminate your Agreement before the date
          the revised Terms become effective, your continued access to or use of
          the Site will constitute acceptance of the revised Terms.
        </p>
        <p>
          <a name="sec4"></a>
          <strong>4. </strong>
          <strong>
            <u>
              Proprietary Rights in Service Content and Software; Limited
              License
            </u>
          </strong>
        </p>
        <p>
          4.1 All content on the Site, including but not limited to designs,
          text, graphics, pictures, video, information, software, music, sound
          and other files, and their selection and arrangement (the{" "}
          <strong>"Site Content</strong>"), are the proprietary property of
          COMPANY with all rights reserved. No Site Content may be modified,
          copied, distributed, framed, reproduced, republished, downloaded,
          displayed, posted, transmitted, or sold in any form or by any means,
          in whole or in part, without our prior written permission, except as
          provided in the following sentence and except that the foregoing does
          not apply to your own User Content (as defined below) that you legally
          post on the Site. Provided that you are eligible for use of the Site,
          and subject to the this Terms, you are granted a limited license to
          access and use the Site and to download or print a copy of any portion
          of the Site Content solely for your use and not for distribution,
          provided that you keep all copyright or other proprietary notices
          intact. Except for your own User Content (as defined below), you may
          not republish Site Content on any Internet, Intranet or Extranet site
          or incorporate the information in any other database or compilation,
          and any other use of the Site Content is strictly prohibited. In
          connection with your use of the Services, you will not engage in or
          use any data mining, robots, scraping or similar data gathering or
          extraction methods. If you are blocked by COMPANY from accessing the
          Service (including by blocking your IP address), you agree not to
          implement any measures to circumvent such blocking (e.g., by masking
          your IP address or using a proxy IP address).
        </p>
        <p>
          4.2 The technology and software underlying the Services or distributed
          in connection therewith are the property of COMPANY, our subsidiaries,
          affiliates and partners (the "Software"). You agree not to copy,
          modify, create a derivative work of, reverse engineer, reverse
          assemble or otherwise attempt to discover any source code, sell,
          assign, sublicense, or otherwise transfer any right in the Software.
          Any rights not expressly granted herein are reserved by COMPANY.
        </p>
        <p>
          4.3 Any use of the Services or the Site Content other than as
          specifically authorized herein, without our prior written permission,
          is strictly prohibited and will terminate the license granted herein.
          Such unauthorized use may also violate applicable laws including
          without limitation copyright and trademark laws and applicable
          communications regulations and statutes. Unless explicitly stated
          herein, nothing in these Terms shall be construed as conferring any
          license to intellectual property rights, whether by estoppel,
          implication or otherwise. This license is revocable by us at any time
          without notice and with or without cause.
        </p>
        <p>
          <strong>5. </strong>
          <strong>
            <u>Trademark Rights</u>
          </strong>
        </p>
        <p>
          "Swarm Age", "SwarmAge" and other graphics, logos, designs, page
          headers, button icons, scripts and service names are registered
          trademarks, trademarks or trade dress of COMPANY in the U.S. and/or
          other countries. Our trademarks and trade dress may not be used,
          including as part of trademarks and/or as part of domain names, in
          connection with any product, campaign, political effort, or service in
          any manner that is likely to cause confusion and may not be copied,
          imitated, or used, in whole or in part, without our prior written
          permission.
        </p>
        <p>
          <a name="sec5"></a>
          <strong>6. </strong>
          <strong>
            <u>User Content</u>
          </strong>
        </p>
        <p>
          6.1 COMPANY may, at its sole discretion, enable Users to (i) create,
          upload, post, send, receive and store content, such as text, photos,
          audio, video, or other materials and information on or through the
          Site (" <strong>User Content</strong>"); and (ii) access and view User
          Content and any content that COMPANY itself makes available on or
          through the Site, including proprietary Content and any content
          licensed or authorized for use by or through COMPANY from a third
          party ("<strong>Content</strong>" and together with User Content, "
          <strong>Collective Content</strong>").
        </p>
        <p>
          6.2 The Site, Content, and User Content may in its entirety or in part
          be protected by copyright, trademark, and/or other laws of the United
          States and other countries. You acknowledge and agree that the Site
          and Content, including all associated intellectual property rights,
          are the exclusive property of COMPANY and/or its licensors or
          authorizing third parties. You will not remove, alter or obscure any
          copyright, trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the Site, Content or User Content. All
          trademarks, service marks, logos, trade names, and any other source
          identifiers of COMPANY used on or in connection with the Site and
          Content are trademarks or registered trademarks of COMPANY in the
          United States and abroad.
        </p>
        <p>
          6.3 You will not use, copy, adapt, modify, prepare derivative works
          of, distribute, license, sell, transfer, publicly display, publicly
          perform, transmit, broadcast or otherwise exploit the Site or
          Collective Content, except to the extent you are the legal owner of
          certain User Content or as expressly permitted in these Terms or in
          any other agreement entered into between you and COMPANY. No licenses
          or rights are granted to you by implication or otherwise under any
          intellectual property rights owned or controlled by COMPANY or its
          licensors, except for the licenses and rights expressly granted in
          these Terms.
        </p>
        <p>
          6.4 Subject to your compliance with these Terms, COMPANY grants you a
          limited, non-exclusive, non-sublicensable, revocable, non-transferable
          license to (i) download and use the Application on your personal
          device(s); and (ii) access and view any Collective Content made
          available on or through the Site and accessible to you, solely for
          your personal and non-commercial use.related to the Purpose.
        </p>
        <p>
          6.5 By creating, uploading, posting, sending, receiving, storing, or
          otherwise making available any User Content on or through the Site,
          you grant to COMPANY a non-exclusive, worldwide, royalty-free,
          irrevocable, perpetual (or for the term of the protection),
          sub-licensable and transferable license to such User Content to
          access, use, store, copy, modify, prepare derivative works of,
          distribute, publish, transmit, stream, broadcast, and otherwise
          exploit in any manner such User Content to provide and/or promote the
          Site, in any media or platform. Unless you provide specific consent,
          COMPANY does not claim any ownership rights in any User Content and
          nothing in these Terms will be deemed to restrict any rights that you
          may have to use or exploit your User Content.
        </p>
        <p>
          6.6 You are solely responsible for all User Content that you make
          available on or through the Site. Accordingly, you represent and
          warrant that: (i) you either are the sole and exclusive owner of all
          User Content that you make available on or through the Site or you
          have all rights, licenses, consents and releases that are necessary to
          grant to COMPANY the rights in and to such User Content, as
          contemplated under these Terms; and (ii) neither the User Content nor
          your posting, uploading, publication, submission or transmittal of the
          User Content or COMPANY's use of the User Content (or any portion
          thereof) will infringe, misappropriate or violate a third party's
          patent, copyright, trademark, trade secret, moral rights or other
          proprietary or intellectual property rights, or rights of publicity or
          privacy, or result in the violation of any applicable law or
          regulation.
        </p>
        <p>
          6.7 You will not post, upload, publish, submit or transmit any User
          Content that: (i) is fraudulent, false, misleading (directly or by
          omission or failure to update information) or deceptive; (ii) is
          defamatory, libelous, obscene, pornographic, vulgar or offensive;
          (iii) promotes discrimination, bigotry, racism, hatred, harassment or
          harm against any individual or group; (iv) is violent or threatening
          or promotes violence or actions that are threatening to any other
          person; (v) promotes illegal or harmful activities or substances; or
          (vi) violates any other COMPANY policy. COMPANY may, without prior
          notice, remove or disable access to any User Content that COMPANY
          finds to be in violation of these Terms, or otherwise may be harmful
          or objectionable to COMPANY, its Users, third parties, or property.
        </p>
        <p>
          6.8 Use of the Services may require the use of certain third-party
          products and services ("<strong>Third Party Services</strong>"). Use
          of any Third Party Services is at your sole risk and will be governed
          by separate terms and conditions, separate privacy policies relating
          to usage of data you may share through the Third Party Services in the
          course of using the Services, other applicable policies, and may
          include separate fees and charges. COMPANY may display content from
          third parties through the Services or may provide information about or
          links to Third Party Services. Your interactions with any such third
          parties, and any terms, conditions, warranties, or representations
          associated with such interactions, are solely between you and the
          applicable third parties. COMPANY is not responsible or liable for any
          loss or damage of any sort incurred as the result of any such
          interactions or as the result of the presence of such third-party
          information made available through the Services.
        </p>
        <p>
          6.9 COMPANY respects copyright law and expects its Users to do the
          same. If you believe that any content on the Site infringes copyrights
          you own, please notify us promptly.
        </p>
        <p>
          <a name="sec7"></a>
          <a name="sec14"></a>
          <strong>7. </strong>
          <strong>
            <u>Prohibited Activities</u>
          </strong>
        </p>
        <p>
          7.1 You are solely responsible for compliance with any and all laws,
          rules, regulations, and tax obligations that may apply to your use of
          the Site. In connection with your use of the Site, you will not and
          will not assist or enable others to:
        </p>
        <p>
          &#183; breach or circumvent any applicable laws or regulations,
          agreements with third-parties, third-party rights, or our Terms.
        </p>
        <p>&#183; use the Site for any purpose other than the Purpose.</p>
        <p>
          &#183; use the Site or Collective Content for any commercial or other
          purposes that are not related to the Purpose or expressly permitted by
          these Terms or in a manner that falsely implies COMPANY endorsement,
          partnership or otherwise misleads others as to your affiliation with
          COMPANY.
        </p>
        <p>
          &#183; copy, store or otherwise access or use any information,
          including personally identifiable information about any other User,
          contained on the Site in any way that is inconsistent with these Terms
          or that otherwise violates the privacy rights of Users or third
          parties.
        </p>
        <p>
          &#183; use the Site in connection with the distribution of unsolicited
          commercial messages ("spam");
        </p>
        <p>
          &#183; discriminate against or harass anyone on the basis of race,
          national origin, religion, gender, gender identity, physical or mental
          disability, medical condition, marital status, age or sexual
          orientation, or otherwise engage in any abusive or disruptive
          behavior.
        </p>
        <p>
          &#183; use, display, mirror or frame the Site or Collective Content,
          or any individual element within the Site, COMPANY's name, any COMPANY
          trademark, logo or other proprietary information, or the layout and
          design of any page or form contained on a page in the Site, without
          COMPANY's express written consent;
        </p>
        <p>
          &#183; dilute, tarnish or otherwise harm the COMPANY brand in any way,
          including through unauthorized use of Collective Content, registering
          and/or using COMPANY or derivative terms in domain names, trade names,
          trademarks or other source identifiers, or registering and/or using
          domains names, trade names, trademarks or other source identifiers
          that closely imitate or are confusingly similar to COMPANY domains,
          trademarks, taglines, promotional campaigns or Collective Content;
        </p>
        <p>
          &#183; use any robots, spider, crawler, scraper or other automated
          means or processes to access, collect data or other content from or
          otherwise interact with the Site for any purpose.
        </p>
        <p>
          &#183; avoid, bypass, remove, deactivate, impair, descramble, or
          otherwise attempt to circumvent any technological measure implemented
          by COMPANY or any of COMPANY's providers or any other third party to
          protect the Site.
        </p>
        <p>
          &#183; attempt to decipher, decompile, disassemble or reverse engineer
          any of the software used to provide the Site.
        </p>
        <p>
          &#183; take any action that damages or adversely affects or could
          damage or adversely affect the performance or proper functioning of
          the Site.
        </p>
        <p>
          &#183; violate or infringe anyone else's rights or otherwise cause
          harm to anyone.
        </p>
        <p>
          7.2 You acknowledge that COMPANY has no obligation to monitor the
          access to or use of the Site by any User or to review, disable access
          to, or edit any User Content, but has the right to do so to (i)
          operate, secure and improve the Site (including without limitation for
          fraud prevention, risk assessment, investigation and customer support
          purposes); (ii) ensure Users' compliance with these Terms; (iii)
          comply with applicable law or the order or requirement of a court, law
          enforcement or other administrative agency or governmental body; (iv)
          respond to User Content that it determines is harmful or
          objectionable; or (v) as otherwise set forth in these Terms. Users
          agree to cooperate with and assist COMPANY in good faith, and to
          provide COMPANY with such information and take such actions as may be
          reasonably requested by COMPANY with respect to any investigation
          undertaken by COMPANY or a representative of COMPANY regarding the use
          or abuse of the Site.
        </p>
        <p>
          7.3 If you feel that any User you interact with, whether online or in
          person, is acting or has acted inappropriately, including but not
          limited to anyone who (i) engages in offensive, violent or sexually
          inappropriate behavior, (ii) you suspect of stealing from you, or
          (iii) engages in any other disturbing conduct, you should immediately
          report such person to the appropriate authorities and then to COMPANY
          by contacting us with your police station and report number (if
          available); provided that your report will not obligate us to take any
          action beyond that required by law (if any) or cause us to incur any
          liability to you.
        </p>
        <p>
          <a name="sec15"></a>
          <strong>8. </strong>
          <strong>
            <u>Term and Termination, Suspension and other Measures</u>
          </strong>
        </p>
        <p>
          8.1 This Agreement shall be effective for a 30-day term, at the end of
          which it will automatically and continuously renew for subsequent
          30-day terms until such time when you or COMPANY terminate the
          Agreement in accordance with this section.
        </p>
        <p>
          8.2 You may terminate this Agreement at any time by contacting us at{" "}
          <a href="mailto:admin@SwarmAge.org">admin@SwarmAge.org</a> and
          providing notice of your intent to terminate. You may also contact us
          at the forgoing address to submit (i) a data access request, pursuant
          to which we will provide you with confirmation of all personal data
          currently being stored or otherwise used by us and relating to you,
          and access to the same; (ii) a data rectification request, pursuant to
          which we will rectify any incorrect data currently being stored or
          otherwise used by us and relating to you; and/or (iii) a data deletion
          request, pursuant to which we will delete all personal data currently
          being stored or otherwise used by us and relating to you.
        </p>
        <p>
          8.3 Without limiting our rights specified below, COMPANY may terminate
          this Agreement for convenience at any time by giving you thirty (30)
          days' notice via email to your registered email address.
        </p>
        <p>
          8.4 COMPANY may terminate this Agreement immediately and without
          notice if (i) you have materially breached your obligations under
          these Terms, (ii) you have violated applicable laws, regulations or
          third party rights, or (iii) COMPANY believes in good faith that such
          action is reasonably necessary to protect the personal safety or
          property of COMPANY, its Users, or third parties (for example in the
          case of fraudulent behavior of a User).
        </p>
        <p>
          8.5 In addition, COMPANY may take any of the following measures (i) to
          comply with applicable law, or the order or request of a court, law
          enforcement or other administrative agency or governmental body; (ii)
          if you have breached these Terms, applicable laws, regulations, or
          third party rights; (iii) if you have provided inaccurate, fraudulent,
          outdated or incomplete information during the COMPANY account
          registration, listing process or thereafter; (iv) if COMPANY believes
          in good faith that such action is reasonably necessary to protect the
          personal safety or property of COMPANY, its Users, or third parties,
          or to prevent fraud or other illegal activity; (v) for any other{" "}
          <em>bona fide</em> reason determined by COMPANY in a good faith
          exercise of its reasonable business judgment:
        </p>
        <p>&#183; limit your access to or use of the Site.</p>
        <p>
          &#183; temporarily or permanently revoke any special status associated
          with your COMPANY account; or
        </p>
        <p>
          &#183; temporarily or in case of severe or repeated offenses
          permanently suspend your COMPANY account.
        </p>
        <p>
          8.6 When this Agreement has been terminated, you are not entitled to a
          restoration of your COMPANY account or any of your User Content. If
          your access to or use of the Site has been limited or your COMPANY
          account has been suspended or this Agreement has been terminated by
          us, you may not register a new COMPANY account or access and use the
          Site through a COMPANY account of another User.
        </p>
        <p>
          8.7 If you or we terminate this Agreement, the clauses of these Terms
          that reasonably should survive termination to effect the purposes of
          the Agreement will remain in effect.
        </p>
        <p>
          <a name="sec16"></a>
          <strong>9. </strong>
          <strong>
            <u>Disclaimers</u>
          </strong>
        </p>
        <p>
          <strong>
            If you choose to use the Site or Collective Content, you do so
            voluntarily and at your sole risk. The Site and Collective Content
            is provided "as is", without warranty of any kind, either express or
            implied.
          </strong>
        </p>
        <p>
          <strong>
            You agree that you have had whatever opportunity you deem necessary
            to investigate the Services, laws, rules, or regulations that may be
            applicable to your use of the Site and that you are not relying upon
            any statement of law or fact made by COMPANY relating to such use.
          </strong>
        </p>
        <p>
          <strong>
            The foregoing disclaimers apply to the maximum extent permitted by
            law. You may have other statutory rights. However, the duration of
            statutorily required warranties, if any, shall be limited to the
            maximum extent permitted by law.
          </strong>
        </p>
        <p>
          <a name="sec17"></a>
          <strong>10. </strong>
          <strong>
            <u>Liability</u>
          </strong>
        </p>
        <p>
          <strong>
            You acknowledge and agree that, to the maximum extent permitted by
            law, the entire risk arising out of your access to and use of the
            Site and Collective Content, your publishing or viewing of any
            content via the Site or any other interaction you have with other
            Users whether in person or online remains with you. Neither COMPANY,
            its officers, directors, employees and agents, nor any other party
            involved in creating, producing, or delivering the Site or
            Collective Content will be liable for any incidental, special,
            exemplary or consequential damages, including lost profits, loss of
            data or loss of goodwill, service interruption, computer damage or
            system failure or the cost of substitute products or services, or
            for any damages for personal or bodily injury or emotional distress
            arising out of or in connection with (i) these Terms, (ii) from the
            use of or inability to use the Site or Collective Content, or (iii)
            from any communications, interactions or meetings with other Users
            or other persons with whom you communicate, interact or meet with as
            a result of your use of the Site, whether based on warranty,
            contract, tort (including negligence), product liability or any
            other legal theory, and whether or not COMPANY has been informed of
            the possibility of such damage, even if a limited remedy set forth
            herein is found to have failed of its essential purpose. In no event
            will COMPANY's aggregate liability arising out of or in connection
            with these Terms and your use of the Site, or from the use of or
            inability to use the Site or Collective Content and in connection
            therewith, exceed the amounts you have paid or owe for use of the
            Site in the twelve (12) month period prior to the event giving rise
            to the liability, or one hundred U.S. dollars (US$100), if no such
            payments have been made, as applicable. The limitations of damages
            set forth above are fundamental elements of the basis of the bargain
            between COMPANY and you. Some jurisdictions do not allow the
            exclusion or limitation of liability for consequential or incidental
            damages, so the above limitation may not apply to you.
          </strong>
        </p>
        <p>
          <a name="sec18"></a>
          <strong></strong>
        </p>
        <p>
          <strong>11. </strong>
          <strong>
            <u>Indemnification</u>
          </strong>
        </p>
        <p>
          You agree to release, defend (at COMPANY's option), indemnify, and
          hold COMPANY and its affiliates and subsidiaries, and their officers,
          directors, employees and agents, harmless from and against any claims,
          liabilities, damages, losses, and expenses, including, without
          limitation, reasonable legal and accounting fees, arising out of or in
          any way connected with (i) your breach of these Terms, (ii) your
          improper use of the Site or any Services, (iii) your interaction with
          any User, including without limitation any injuries, losses or damages
          (whether compensatory, direct, incidental, consequential or otherwise)
          of any kind arising in connection with or as a result of such
          interaction or use, or (iv) your breach of any laws, regulations or
          third party rights.
        </p>
        <p>
          <a name="sec19"></a>
          <strong>12. </strong>
          <strong>
            <u>Dispute Resolution and Arbitration Agreement</u>
          </strong>
        </p>
        <p>
          12.1 This Dispute Resolution and Arbitration Agreement shall apply if
          you (i) reside in the United States; or (ii) do not reside in the
          United States but bring any claim against COMPANY in the United
          States.
        </p>
        <p>
          12.2 <em>Overview of Dispute Resolution Process</em>. COMPANY is
          committed to participating in a consumer-friendly dispute resolution
          process. To that end, these Terms provide for a two-part process for
          individuals to whom this section applies: (1) an informal negotiation
          directly with COMPANY's customer service team, and (2) a binding
          arbitration administered by the American Arbitration Association ("
          <strong>AAA</strong>") using its specially designed Consumer
          Arbitration Rules (as modified by this Section). Specifically, the
          process provides:
        </p>
        <p>
          &#183; Claims can be filed with AAA online (
          <a href="http://www.adr.org/cs/idcplg?IdcService=GET_FILE&amp;dDocName=ADRSTAGE2034889&amp;RevisionSelectionMethod=LatestReleased">
            www.adr.org
          </a>
          );
        </p>
        <p>
          &#183; Arbitrators must be neutral, and no party may unilaterally
          select an arbitrator.
        </p>
        <p>
          &#183; Arbitrators must disclose any bias, interest in the result of
          the arbitration, or relationship with any party.
        </p>
        <p>
          &#183; Parties retain the right to seek relief in small claims court
          for certain claims, at their option.
        </p>
        <p>&#183; The initial filing fee for the consumer is capped at $200.</p>
        <p>
          &#183; The consumer gets to elect the hearing location and can elect
          to participate live, by phone, video conference, or, for claims under
          $25,000, by the submission of documents.
        </p>
        <p>
          &#183; The arbitrator can grant any remedy that the parties could have
          received in court to resolve the party's individual claim.
        </p>
        <p>
          12.3 <em>Pre-Arbitration Dispute Resolution and Notification</em>.
          Prior to initiating an arbitration, you and COMPANY each agree to
          notify the other party of the dispute and attempt to negotiate an
          informal resolution to it first. We will contact you at the email
          address you have provided to us; you can contact COMPANY's customer
          service team by emailing us at the contact addresses provided on the
          Site. If after a good faith effort to negotiate one of us feels the
          dispute has not and cannot be resolved informally, the party intending
          to pursue arbitration agrees to notify the other party via email prior
          to initiating the arbitration. In order to initiate arbitration, a
          claim must be filed with the AAA and the written Demand for
          Arbitration (available at{" "}
          <a href="http://www.adr.org/cs/idcplg?IdcService=GET_FILE&amp;dDocName=ADRSTAGE2034889&amp;RevisionSelectionMethod=LatestReleased">
            www.adr.org
          </a>
          ) provided to the other party, as specified in the AAA Rules.
        </p>
        <p>
          12.4{" "}
          <strong>
            <em>AGREEMENT TO ARBITRATE</em>
          </strong>
          <strong>
            . YOU AND COMPANY MUTUALLY AGREE THAT ANY DISPUTE, CLAIM OR
            CONTROVERSY ARISING OUT OF OR RELATING TO THESE TERMS OR THE BREACH,
            TERMINATION, ENFORCEMENT OR INTERPRETATION THEREOF, OR TO THE USE OF
            THE SITE, ANY COMPANY SERVICE OR THE COLLECTIVE CONTENT
            (COLLECTIVELY, "DISPUTES") WILL BE SETTLED BY BINDING ARBITRATION
            (THE "ARBITRATION AGREEMENT"). IF THERE IS A DISPUTE ABOUT WHETHER
            THIS ARBITRATION AGREEMENT CAN BE ENFORCED OR APPLIES TO OUR
            DISPUTE, YOU AND COMPANY AGREE THAT THE ARBITRATOR WILL DECIDE THAT
            ISSUE.
          </strong>
        </p>
        <p>
          12.5 <em>Exceptions to Arbitration Agreement</em>. You and COMPANY
          each agree that the following claims are exceptions to the Arbitration
          Agreement and will be brought in a judicial proceeding in a court of
          competent jurisdiction: (i) Any claim related to actual or threatened
          infringement, misappropriation or violation of a party's copyrights,
          trademarks, trade secrets, patents, or other intellectual property
          rights; (ii) Any claim seeking emergency injunctive relief based on
          exigent circumstances (e.g., imminent danger or commission of a crime,
          hacking, cyber-attack).
        </p>
        <p>
          12.6 <em>Arbitration Rules and Governing Law</em>. This Arbitration
          Agreement evidences a transaction in interstate commerce and thus the
          Federal Arbitration Act governs the interpretation and enforcement of
          this provision. The arbitration will be administered by AAA in
          accordance with the Consumer Arbitration Rules (the "
          <strong>AAA Rules</strong>") then in effect, except as modified here.
          The AAA Rules are available at{" "}
          <a href="http://www.adr.org/">www.adr.org</a> or by calling the AAA at
          1-800-778-7879.
        </p>
        <p>
          12.7 <em>Modification to AAA Rules - Arbitration Hearing/Location</em>
          . In order to make the arbitration most convenient to you, COMPANY
          agrees that any required arbitration hearing may be conducted, at your
          option, (a) in the county where you reside; (b) in Carson City,
          Nevada; (c) in any other location to which you and COMPANY both agree;
          (d) via phone or video conference; or (e) for any claim or
          counterclaim under $25,000, by solely the submission of documents to
          the arbitrator
        </p>
        <p>.</p>
        <p>
          12.8 <em>Arbitrator's Decision</em>. The arbitrator's decision will
          include the essential findings and conclusions upon which the
          arbitrator based the award. Judgment on the arbitration award may be
          entered in any court with proper jurisdiction. The arbitrator may
          award declaratory or injunctive relief only on an individual basis and
          only to the extent necessary to provide relief warranted by the
          claimant's individual claim.
        </p>
        <p>
          12.9{" "}
          <strong>
            <em>JURY TRIAL WAIVER</em>
          </strong>
          <strong>
            . YOU AND COMPANY ACKNOWLEDGE AND AGREE THAT WE ARE EACH WAIVING THE
            RIGHT TO A TRIAL BY JURY AS TO ALL ARBITRABLE DISPUTES.
          </strong>
        </p>
        <p>
          12.10{" "}
          <strong>
            <em>NO CLASS ACTIONS OR REPRESENTATIVE PROCEEDINGS</em>
          </strong>
          <strong>
            . YOU AND COMPANY ACKNOWLEDGE AND AGREE THAT WE ARE EACH WAIVING THE
            RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS USER IN ANY PURPORTED
            CLASS ACTION LAWSUIT, CLASS-WIDE ARBITRATION, PRIVATE
            ATTORNEY-GENERAL ACTION, OR ANY OTHER REPRESENTATIVE PROCEEDING AS
            TO ALL DISPUTES. FURTHER, UNLESS YOU AND COMPANY BOTH OTHERWISE
            AGREE IN WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE
            PARTY'S CLAIMS AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF ANY
            CLASS OR REPRESENTATIVE PROCEEDING. IF THIS PARAGRAPH IS HELD
            UNENFORCEABLE WITH RESPECT TO ANY DISPUTE, THEN THE ENTIRETY OF THE
            ARBITRATION AGREEMENT WILL BE DEEMED VOID WITH RESPECT TO SUCH
            DISPUTE.
          </strong>
        </p>
        <p>
          12.11 <em>Severability</em>. Except as provided in the immediately
          preceding paragraph, in the event that any portion of this Arbitration
          Agreement is deemed illegal or unenforceable, such provision shall be
          severed and the remainder of the Arbitration Agreement shall be given
          full force and effect.
        </p>
        <p>
          12.12 <em>Changes</em>. Notwithstanding the provisions of Section 3{" "}
          <strong>("Modification of These</strong> <strong>Terms</strong>"), if
          COMPANY changes this Section ("Dispute Resolution and Arbitration
          Agreement") after the date you last accepted these Terms (or accepted
          any subsequent changes to these Terms), you may reject any such change
          by sending us written notice (including by email) within thirty (30)
          days of the date such change became effective. By rejecting any
          change, you are agreeing that you will arbitrate any Dispute between
          you and COMPANY in accordance with the provisions of the "Dispute
          Resolution and Arbitration Agreement" section as of the date you last
          accepted these Terms (or accepted any subsequent changes to these
          Terms).
        </p>
        <p>
          12.13 <em>Survival</em>. Except as otherwise provided in this section,
          this section will survive any termination of these Terms and will
          continue to apply even if you stop using the Site or terminate your
          COMPANY account.
        </p>
        <p>
          <a name="sec20"></a>
          <strong>13. </strong>
          <strong>
            <u>Feedback</u>
          </strong>
        </p>
        <p>
          13.1 We welcome and encourage you to provide feedback, comments and
          suggestions for improvements to the Site ("<strong>Feedback</strong>
          "). You may submit Feedback by emailing us, through the Contact
          section of the Site, or by other means of communication. Any Feedback
          you submit to us will be considered non-confidential and
          non-proprietary to you. By submitting Feedback to us, you grant us a
          non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable,
          perpetual license to use and publish those ideas and materials for any
          purpose, without compensation to you.
        </p>
        <p>
          <a name="sec21"></a>
          <strong>14. </strong>
          <strong>
            <u>Applicable Law and Jurisdiction</u>
          </strong>
        </p>
        <p>
          14.1 If you reside in the United States, these Terms will be
          interpreted in accordance with the laws of the State of Nevada and the
          United States of America, without regard to conflict-of-law
          provisions. Judicial proceedings (other than small claims actions)
          that are excluded from the Arbitration Agreement above must be brought
          in state or federal court in Carson City, Nevada, unless we both agree
          to some other location. You and we both consent to venue and personal
          jurisdiction in Carson City, Nevada.
        </p>
        <p>
          <strong>15. </strong>
          <strong>
            <u>Telephone Contact and Recording Policy</u>
          </strong>
        </p>
        <p>
          15.1 By providing us with a telephone number for a mobile device,
          including a number that you later convert to a mobile device number,
          you are expressly consenting to receiving communications-including but
          not limited to prerecorded or artificial voice message calls, text
          messages, and calls made by an automatic telephone dialing system-from
          us and our affiliates and agents at that number, about any product or
          services offered by COMPANY. This express consent applies to each such
          telephone number that you provide to us now or in the future. Calls
          and messages may incur access fees from your mobile services provider.
          You understand that you need not provide this consent as a condition
          of obtaining goods or services from COMPANY, and that you may decline
          to provide or revoke your consent at any time by emailing [ ] or by
          any other method that reasonably ensures we receive your revocation.
        </p>
        <p>
          15.2 By providing us with a telephone number, you agree that COMPANY
          may record any telephone conversation with you (or any another
          individual) during any telephone call to or from that number for
          training purposes, whether or not the call was initiated by you, and
          whether or COMPANY disclosed the fact that the call was recorded
          during the call.
        </p>
        <p>
          <strong>16. </strong>
          <strong>
            <u>Copyright Complaints</u>
          </strong>
        </p>
        <p>
          16.1 If you believe that any material on the Site infringes upon any
          copyright which you own or control, you may send a written
          notification of such infringement to our Designated Agent as set forth
          below:
        </p>
        <p>[ ]</p>
        <p>
          16.2 To meet the notice restrictions under the Digital Millennium
          Copyright Act, the notification must be a written communication that
          includes the following:
        </p>
        <p>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed.
        </p>
        <p>
          Identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works at a single online site are covered
          by a single notification, a representative list of such works at that
          site.
        </p>
        <p>
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit us to locate the material.
        </p>
        <p>
          Information reasonably sufficient to permit us to contact the
          complaining party, such as an address, telephone number and, if
          available, an electronic mail address at which the complaining party
          may be contacted.
        </p>
        <p>
          A statement that the complaining party has a good-faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent or the law; and
        </p>
        <p>
          A statement that the information in the notification is accurate, and
          under penalty of perjury, that the complaining party is authorized to
          act on behalf of the owner of an exclusive right that is allegedly
          infringed.
        </p>
        <p>
          <a name="sec22"></a>
          <strong>17. </strong>
          <strong>
            <u>General Provisions</u>
          </strong>
        </p>
        <p>
          17.1 These Terms constitute the entire Agreement between COMPANY, and
          you pertaining to your use of the Site and supersede any and all prior
          oral or written understandings or agreements between COMPANY and you
          in relation to the access to and use of the Site. In addition to these
          Terms, you may enter into other agreements with us or others that will
          govern your use of the Services or related services offered by us or
          others. If there is any contradiction between these Terms and another
          written agreement applicable to specific aspects of the Services, the
          other written agreement shall take govern the specific aspects of the
          Services to which it applies.
        </p>
        <p>
          17.2 No joint venture, partnership, employment, or agency relationship
          exists between you and COMPANY as a result of this Agreement or your
          use of the Site.
        </p>
        <p>
          17.3 These Terms do not and are not intended to confer any rights or
          remedies upon any person other than the parties.
        </p>
        <p>
          17.4 If any provision of these Terms is held to be invalid or
          unenforceable, such provision will be struck and will not affect the
          validity and enforceability of the remaining provisions.
        </p>
        <p>
          17.5 COMPANY's failure to enforce any right or provision in these
          Terms will not constitute a waiver of such right or provision unless
          acknowledged and agreed to by us in writing. Except as expressly set
          forth in these Terms, the exercise by either party of any of its
          remedies under these Terms will be without prejudice to its other
          remedies under these Terms or otherwise permitted under law.
        </p>
        <p>
          17.6 You may not assign, transfer or delegate this Agreement and your
          rights and obligations hereunder without COMPANY's prior written
          consent. COMPANY may without restriction assign, transfer or delegate
          this Agreement and any rights and obligations hereunder, at its sole
          discretion, with 30 days prior notice. Your right to terminate this
          Agreement at any time remains unaffected.
        </p>
        <p>
          17.7 Unless specified otherwise, any notices or other communications
          to Users permitted or required under this Agreement, will be in
          writing and given by COMPANY via email, Site notification, or
          messaging service (including SMS).
        </p>
        <p>
          17.8 If you have any questions about these Terms, please email us at
          admin@SwarmAge.org.
        </p>
      </div>
    );
  }
}

export default TOU;
