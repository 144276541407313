export const QUESTION = "What are our current supporter goals?";
export const MANIFESTO_QUESTION = "What is our purpose?";
export const BELT_LEVEL = [
  0,
  0.05,
  0.1,
  0.2,
  0.39,
  0.78,
  1.6,
  3.1,
  6.3,
  13,
  25,
  100,
];
export const MANIFESTO_CONTEXT = `
<strong>Welcome to the Swarm Age!</strong> This site is intended to show you how this grassroots empowering web-tool works. Please, play around with it.</br></br>
<b>The Swarm Age map.</b> The map shows all states, counties and precincts in the United States. The size of each square is set based on the number of supporters to the number of registered voters.</br></br>
<b>Sign-up to be a supporter.</b> When people become supporters of a movement, they input their state, county, and precinct. This is used to create the Swarm Age map which allows all members of a movement to see where they have supporters, and where they need more.
</br></br><b>Supporter goals.</b> To motivate supporters, supporter-levels exist to give supporters short-range targets to aim for. Everyone just needs to attract a few more supporters to keep the movement moving toward its goal. </br></br>  
<b>Swarm Age exists to make our Democracy stronger and more resilient</b> through the empowerment of the civil population. For too long, citizens have stood by unable to affect real change, unknowing of how to guide a movement toward a successful conclusion. They march, they write, they engage, but nothing gets better. It seems hopeless. But no longer.</br></br>
<b>Swarm Age provides focus.</b> The movement’s focus becomes clear; “gather supporters until there are enough for a successful ballot measure.” Regardless of the initiative, the goal remains the same. Swarm Age is not the tool of advocacy, but it does provide links to organizations that affiliate under the purpose of the movement.  But how close is the movement to achieving that goal? Does support exist county-wide, state-wide, nation-wide? </br></br> 
<b>Swarm Age provides an understanding of progress</b> for the entire movement. All supporters can understand, down to the precinct level, where supporters are plentiful and where more supporters are needed. By examining locations of success, supporters can learn useful lessons. By finding pockets of minimal support, supporters can see where they must engage more. But gaining enough supporters in a nationwide movement can feel overwhelming and seemingly unreachable. How can the movement ever hope to accomplish such lofty objectives?  </br></br>
<b>Swarm Age provides motivation.</b> Every region has supporter percentages broken down into target supporter levels. Early goals require only a few supporters to achieve, but later ones need greater and greater numbers.  But there are also more supporters to work together to accomplish these later goals. All levels are kept manageable, and the movement can all see and appreciate the accomplishment of these levels. Progress is evident and motivating. A person can hardly conceive of gaining a million supporters to pass a ballot measure, but to obtain just one? That they can do.  Swarm Age puts into practice the concept that a journey of a million steps begins with one. </br></br>
We at Swarm Age are focused, enthusiastic, visionary, grounded, and supportive. We believe that the best way for a Democracy to function effectively and powerfully is for its citizens to have the tools to bring their ideas forward to the rest of society for discussion, debate, and synthesis. <b>Swarm Age empowers people, empowers democracy, and, we are hopeful, will change the world.  We believe we are entering a new age of Democracy, the Swarm Age.</b>`;

export const levels = [
  {
    level: 0,
    value: ".00%",
    blockColor: "#ffffff",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 1,
    value: ".05%",
    blockColor: "#fcfbfd",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 2,
    value: ".10%",
    blockColor: "#efedf5",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 3,
    value: ".20%",
    blockColor: "#dadaeb",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 4,
    value: ".39%",
    blockColor: "#bcbddc",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 5,
    value: ".78%",
    blockColor: "#9e9ac8",
    color: "#333333",
    levelColor: "black",
    selected: false,
  },
  {
    level: 6,
    value: "1.6%",
    blockColor: "#807dba",
    color: "#FFFFFF",
    levelColor: "black",
    selected: false,
  },
  {
    level: 7,
    value: "3.1%",
    blockColor: "#6a51a3",
    color: "#FFFFFF",
    levelColor: "black",
    selected: false,
  },
  {
    level: 8,
    value: "6.3%",
    blockColor: "#54278f",
    color: "#FFFFFF",
    levelColor: "black",
    selected: false,
  },
  {
    level: 9,
    value: "13%",
    blockColor: "#3f007d",
    color: "#FFFFFF",
    levelColor: "black",
    selected: false,
  },
  {
    level: "Goal",
    value: "25%",
    blockColor: "#FFFF00",
    color: "#D9001B",
    levelColor: "#D9001B",
    selected: false,
  },
];

export const getCurrentLevel = (levelVal) => {
  if (Number.isNaN(levelVal)) return 0;
  let i = 0;

  for (i = 0; i < BELT_LEVEL.length - 1; ++i) {
    if (levelVal >= BELT_LEVEL[i] && levelVal <= BELT_LEVEL[i + 1]) {
      break;
    }
  }

  if (i === 11) return 0;

  return i;
};
