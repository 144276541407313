import React, { useState } from 'react';
import {Row, Col} from "reactstrap";
import * as constants from './const';
import DropDown from './DropDown';
import './StaticTitleBar.css'

export default function StaticTitleBar() {
    const [drop, setDrop] = useState(false)
    const toggle = () => setDrop(!drop)

    return (
        <Row className = "static-title-bar">
            <Col
                xs="12"
                sm="12"
                md="12"
                className = "static-column highlighted-title tag-line"
            >
                {constants.USA_VOTING}
            </Col>
            <Col
                xs="12"
                sm="12"
                md="12"
                className = "static-column highlighted-title tag-line-second"
            >
                {constants.EMPOWERING}
            </Col>
            <Col
                xs="12"
                sm="12"
                md="12"
                className = "static-column"
            >
                <button className = "btn btn-default affiliated-link" onClick = {() => setDrop(!drop)}>
                    {constants.AFFILIATED_LINK}
                    {!drop && <span className="tooltiptext">click to see links to sites</span> }
                </button>
            </Col>
            <DropDown show={drop} toggle={toggle} />
        </Row>
    );
}