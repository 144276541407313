import React from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import { levels } from '../../containers/MainPage/const'
import './BeltLevelBar.css'

function BeltLevelBar(props) {
    const { curLevel, stateScreenShow, level } = props
    return (
        <>
            {
                !stateScreenShow && level === 'nation' &&
                <Row style = {{margin: '0px', marginTop: '9px'}}>
                    <Col className="pl-0 title-bar">
                        supporter level: <i>as percentage of voters</i>
                    </Col>
                </Row>
            }
            <Row style = {{padding: '10px', display: 'flex'}}>
                {levels.map((level, index) => {
                    return (
                        <div className = 'bar-item' key={index}>
                            <p className = {`level-bar-level${ index === 10 ? ' level-bar-goal' : '' }`} style={{color: level.levelColor}}>
                                { level.level }
                            </p>

                            <div className = {curLevel === index ? 'triangle-down' : 'triangle-none'} />
                            <div className = {`level-item${curLevel === index ? ' level-selected' : '' }`} style={{backgroundColor: level.blockColor, color: level.color}} >
                                {level.value}
                            </div>
                            <div className={curLevel === index ?  'triangle-up' : 'triangle-none'} />
                        </div>
                    )
                })}
            </Row>
        </>
    )
}
export default BeltLevelBar;
