import React, { Component } from "react";
import { Container } from "reactstrap";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { Scrollbar } from "react-scrollbars-custom";
import Parser from "html-react-parser";
import StaticTitleBar from "../../components/StaticTitleBar/StaticTitleBar";
import DynamicHeader from "../../components/dynamicHeader/dynamicHeader";
import Page from "../../components/Page";
import ParentBlock from "../../components/ParentBlock/ParentBlock";
import PercentBlock from "../../components/PercentBlock/PercentBlock";
import SupporterNumber from "../../components/SupporterNumber/SupporterNumber";
import BeltLevelBar from "../../components/BeltLevelBar/BeltLevelBar";
import TreeMap from "../../components/TreeMap";
import ImageButton from "../../components/ImageButton/ImageButton";
import { LoadingSpinner } from "../../components/common/LoadingSpinner";
import {
  setLevel,
  setLoading,
  setCurBeltLevel,
  setStateScreenShow,
  setMapView,
  setPercentView,
} from "../../redux/actions/vote.action";
import * as constants from "./const";
import * as opConsts from "../../redux/constants";
import "./MainPage.css";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widthForTreeMapUpdate: 0,
    };
  }

  componentDidMount() {
    const { setLevel, setLoading, currentSelInfo } = this.props;
    setLoading(true);
    setLevel({ comment: "nation", innerText: "USA", currentSelInfo });

    this.staticHeaderRef = document.querySelector(".static-header");
    this.dynamicHeaderRef = document.querySelector(".dynamic-header");

    window.addEventListener("resize", this.updateWindowDimensions);
  }

  getOffsetTop() {
    if (this.staticHeaderRef && this.dynamicHeaderRef) {
      return (
        this.staticHeaderRef.clientHeight + this.dynamicHeaderRef.clientHeight
      );
    }
    return 0;
  }

  handleClick(comment, innerText) {
    this.props.setLoading(true);
    this.props.setLevel({
      comment,
      innerText,
      currentSelInfo: this.props.currentSelInfo,
    });
  }

  arrowClick() {
    const { stateScreenShow, setStateScreenShow } = this.props;
    setStateScreenShow(!stateScreenShow);
  }

  hoverEvent(level) {
    const { setCurBeltLevel } = this.props;
    if (level === "Goal") level = 10;
    setCurBeltLevel(level);
  }

  updateWindowDimensions = () => {
    this.setState({ widthForTreeMapUpdate: window.width });
  };

  renderDynamicBar() {
    const {
      session,
      sessionUpdated,
      hamburgerMenuOpen,
      toggleHamburgerMenu,
      stateScreenShow,
      setNavigation,
      currentPage,
    } = this.props;
    return !stateScreenShow ? (
      <>
        <DynamicHeader
          session={session}
          sessionUpdated={sessionUpdated}
          hamburgerMenuOpen={hamburgerMenuOpen}
          toggleHamburgerMenu={toggleHamburgerMenu}
        />

        <Page
          currentPage={currentPage}
          setNavigation={setNavigation}
          session={session}
          sessionUpdated={sessionUpdated}
          hamburgerMenuOpen={hamburgerMenuOpen}
          toggleHamburgerMenu={toggleHamburgerMenu}
          offsetTop={41}
        />
      </>
    ) : null;
  }

  renderStaticTitleBar() {
    const { stateScreenShow } = this.props;
    return !stateScreenShow ? <StaticTitleBar /> : null;
  }

  renderParentBlocks() {
    const {
      level,
      currentSelInfo,
      selStatisticInfo,
      stateScreenShow,
      percentViewMode,
    } = this.props;
    let renderBlocks = [];

    // console.log('===', subordinates)

    for (let i = 0; i < opConsts.LEVELS.length; ++i) {
      const comment = opConsts.LEVELS[i];
      const isOpened = comment === level;
      const innerText = currentSelInfo[comment];
      const statistics = selStatisticInfo[comment];

      if (innerText !== "" && statistics) {
        const {
          headVoteTotal,
          headSupportTotal,
          headPercent,
          headRecentPercent,
        } = statistics;
        const percent = !percentViewMode ? headPercent : headRecentPercent;
        const headLevel = constants.getCurrentLevel(headPercent);
        const currentLevel = constants.getCurrentLevel(percent);
        const showVal =
          currentLevel === 10
            ? 0
            : Math.ceil(
                (headVoteTotal * constants.BELT_LEVEL[headLevel + 1]) / 100 -
                  headSupportTotal
              );

        renderBlocks.push(
          <Row key={`row-${i}`} className="block-row">
            <ParentBlock
              noComment={false}
              comment={comment}
              innerText={innerText}
              isOpened={!isOpened || stateScreenShow ? 1 : 0}
              handleClick={() => this.handleClick(comment, innerText)}
              arrowClick={() => this.arrowClick()}
              blockColor={constants.levels[currentLevel].blockColor}
              textColor={constants.levels[currentLevel].color}
            />
            <SupporterNumber number={showVal} />
            <PercentBlock
              percent={constants.BELT_LEVEL[currentLevel].toFixed(2)}
            />
          </Row>
        );
      }
      if (isOpened) {
        break;
      }
    }

    return renderBlocks;
  }

  renderStateScreen() {
    const { level, stateScreenShow, treeMapData, percentViewMode } = this.props;

    if (!stateScreenShow || level === "precinct") return;

    let nextLevel = "precinct";
    for (let i = 0; i < opConsts.LEVELS.length; ++i) {
      if (level === opConsts.LEVELS[i]) {
        nextLevel = opConsts.LEVELS[i + 1];
        break;
      }
    }

    let renderBlocks = [];

    treeMapData &&
      treeMapData.forStatesScreen &&
      treeMapData.forStatesScreen.map((county, index) => {
        const percent = !percentViewMode
          ? county.headPercent
          : county.recentPercent;
        const headLevel = constants.getCurrentLevel(county.headPercent);
        const curLevel = constants.getCurrentLevel(percent);
        const showVal =
          curLevel === 10
            ? 0
            : Math.ceil(
                (county.voters * constants.BELT_LEVEL[headLevel + 1]) / 100 -
                  county.supporters
              );

        return renderBlocks.push(
          <Row key={`screen-${index}`} className="block-row">
            <ParentBlock
              noComment={true}
              comment={nextLevel}
              innerText={county.name}
              isOpened={2}
              handleClick={() => this.handleClick(nextLevel, county.name)}
              blockColor={constants.levels[curLevel].blockColor}
              textColor={constants.levels[curLevel].color}
            />
            <SupporterNumber number={showVal} />
            <PercentBlock percent={constants.BELT_LEVEL[curLevel].toFixed(2)} />
          </Row>
        );
      });
    return (
      <Scrollbar className="scroll-bar">
        <Container style={{ position: "relative" }}>{renderBlocks}</Container>
      </Scrollbar>
    );
  }

  renderParentDescption() {
    const {
      level,
      currentSelInfo,
      selStatisticInfo,
      stateScreenShow,
    } = this.props;

    if (level !== "nation" || stateScreenShow) return;

    const innerText = currentSelInfo[level];
    const statistics = selStatisticInfo[level];

    if (statistics) {
      const { headVoteTotal, headSupportTotal, headPercent } = statistics;
      const currentLevel = constants.getCurrentLevel(headPercent);
      const number = Math.ceil(
        (headVoteTotal * constants.BELT_LEVEL[currentLevel + 1]) / 100 -
          headSupportTotal
      );
      const percent = constants.BELT_LEVEL[currentLevel].toFixed(2);

      return (
        <>
          <Row>
            <Col
              xs="8"
              sm="8"
              md="8"
              className="description-block description-left"
            >
              In the <strong>{innerText}</strong> we need{" "}
              <span className="highlighted">{number}</span> more supporters to
              reach our next supporter level
            </Col>
            <Col xs="4" sm="4" md="4" className="description-block">
              <strong>{percent}%</strong> of voters are supporters
            </Col>
          </Row>
        </>
      );
    }
  }

  renderBeltLevelBar() {
    const { curBeltLevel, stateScreenShow, level } = this.props;
    return (
      <BeltLevelBar
        curLevel={curBeltLevel}
        stateScreenShow={stateScreenShow}
        level={level}
      />
    );
  }

  renderTreeMap() {
    const {
      treeMapData,
      level,
      mapSelectedView,
      stateScreenShow,
      percentViewMode,
    } = this.props;

    if (level === "precinct" || stateScreenShow) return null;

    let nextLevel = "precinct";
    for (let i = 0; i < opConsts.LEVELS.length; ++i) {
      if (level === opConsts.LEVELS[i]) {
        nextLevel = opConsts.LEVELS[i + 1];
        break;
      }
    }

    return (
      <Row className="treemap" style={{ marginTop: 20, padding: 10 }}>
        <TreeMap
          data={treeMapData}
          hover={(level) => this.hoverEvent(level)}
          nextLevel={nextLevel}
          mouseDown={(comment, innerText) =>
            this.handleClick(comment, innerText)
          }
          mapSelectedView={mapSelectedView}
          stateScreenShow={stateScreenShow}
          percentViewMode={percentViewMode}
          level={level}
          widthForTreeMapUpdate={this.state.widthForTreeMapUpdate}
        />
      </Row>
    );
  }

  renderMapView() {
    const {
      mapSelectedView,
      setMapView,
      percentViewMode,
      setPercentView,
    } = this.props;

    return (
      <Row className="map-view-title">
        <Col xs="4" sm="4" md="4">
          change map view
        </Col>
        <div style={{ display: "flex" }}>
          <ImageButton
            type={0}
            selected={mapSelectedView}
            setMapView={setMapView}
          />
          <ImageButton
            type={1}
            selected={mapSelectedView}
            setMapView={setMapView}
          />
        </div>
        <div style={{ display: "flex" }}>
          <ImageButton
            type={2}
            percentViewMode={percentViewMode}
            setPercentView={setPercentView}
          />
          <ImageButton
            type={3}
            percentViewMode={percentViewMode}
            setPercentView={setPercentView}
          />
        </div>
      </Row>
    );
  }

  render() {
    const { loading, level } = this.props;
    return (
      <>
        {this.renderDynamicBar()}
        {this.renderStaticTitleBar()}
        <LoadingSpinner loading={loading} />
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid grey",
          }}
        >
          <div
            className="treemap-zone"
            style={{ height: level === "precinct" ? "400px" : "750px" }}
          >
            <Row>
              <Col xs="12" sm="12" md="12" className="question-bar">
                {constants.QUESTION}
              </Col>
            </Row>
            {this.renderParentBlocks()}
            {this.renderParentDescption()}
            {this.renderBeltLevelBar()}
            {this.renderStateScreen()}
            {this.renderTreeMap()}
            {this.renderMapView()}
          </div>
          <div className="treemap-zone" style={{ height: "650px" }}>
            <div className="manifesto-zone">
              <Row>
                <Col xs="12" sm="12" md="12" className="question-bar">
                  {constants.MANIFESTO_QUESTION}
                </Col>
              </Row>
              <Scrollbar className="manifesto-zone-context">
                <p> {Parser(constants.MANIFESTO_CONTEXT)} </p>
              </Scrollbar>
            </div>
            <div className="video-zone">
              <iframe
                src="https://www.youtube.com/embed/IQcnFnkPBgA?autoplay=1&loop=1&playlist=IQcnFnkPBgA"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </Row>
        <Row className="end-zone-row">
          <Col xs="12" sm="4" md="4" className="end-left-zone end-zone-block">
            {" "}
            contact and legal information{" "}
          </Col>
          <Col xs="12" sm="4" md="4" className="end-zone-block end-zone-extra">
            <p>
              contacts:
              <br />
              <br />
              SwarmAge management:
              <br />
              - admin@swarmage.org
              <br />
            </p>
          </Col>
          <Col xs="12" sm="4" md="4" className="end-zone-block end-zone-extra">
            <p>
              legal information: <br />
              <br />
              <br />
              <a href="/TermsOfUsage" target="_blank">
                Terms of Service
              </a>
              <br />
              <br />
              <a href="/PrivacyPolicy" target="_blank">
                Privacy Policy
              </a>
            </p>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  level: state.level,
  currentSelInfo: state.currentSelInfo,
  selStatisticInfo: state.selStatisticInfo,
  loading: state.loading,
  curBeltLevel: state.curBeltLevel,
  stateScreenShow: state.stateScreenShow,
  mapSelectedView: state.mapSelectedView,
  percentViewMode: state.percentViewMode,
  subordinates: state.subordinates,
  treeMapData: state.staticInfo,
});

const mapDispatchToProps = {
  setLevel,
  setLoading,
  setStateScreenShow,
  setCurBeltLevel,
  setMapView,
  setPercentView,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MainPage);
